@tailwind base;

// disabled scroll bar
.overflow-auto,
.overflow-x-auto,
.overflow-y-auto,
.overflow-scroll,
.overflow-x-scroll,
.overflow-y-scroll{
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

.overflow-auto::-webkit-scrollbar,
.overflow-x-auto::-webkit-scrollbar,
.overflow-y-auto::-webkit-scrollbar,
.overflow-scroll::-webkit-scrollbar,
.overflow-x-scroll::-webkit-scrollbar,
.overflow-y-scroll::-webkit-scrollbar {
    display:none;
  }


@tailwind components;
// .c-project.expanded{@apply bg-blue01;}
.c-project.nav__group__header::after{@apply text-blue02;}
.c-project.nav__group__header.expanded::after{@apply text-blue02;}

.c-project > .nav__links__header:hover{@apply bg-blue01;}
.c-project > .nav__links__header__v,
.c-project > .nav__link__v,
.c-project > .nav__link__h{@apply border-blue02 left-60px;}

.c-full165 { width: calc(100% + 165px);}

@tailwind utilities;